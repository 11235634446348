<template>
  <v-main v-if="lvl == 1">
    <v-container fluid>
      <v-card class="pa-3 ma-2" flat>
        <v-row dense>
          <v-col cols="3" >
            <div class="headline font-weight-bold pa-1 pb-0">회원관리</div>
            <div class="subtitle caption pa-1">
              <div class="desc_title">
                회원관리 관리자 화면
              </div>
              <v-dialog v-model="dialog" max-width="400px">
                <v-card>
                  <v-toolbar flat dense color="#535a69">
                    <span class="font-weight-bold white--text">회원수정</span>
                    <v-spacer />
                    <v-toolbar-items>
                      <v-tooltip left nudge-bottom=80 >
                        <template v-slot:activator="{ on }">
                          <v-icon color="white" class="load" v-on="on">mdi-comment-question-outline</v-icon>
                        </template>
                        <span>
                          단백질(배수) 선택 가이드<br/>
                          1.6 : 일반적인 근육증가<br/>
                          2.0 : 저칼로리 식이, 초보자, 타고난사람<br/><br/>
                          - 체지방률 20% 초과인 경우<br/> 20%로 환산하어 계산합니다.
                        </span>
                      </v-tooltip>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-text-field v-model="editedItem.name" class="right-input" label="이름"></v-text-field>
                      <v-text-field v-model="editedItem.email" class="right-input" label="이메일" readonly></v-text-field>
                      <v-text-field v-model="editedItem.lvl" class="right-input" label="lvl"></v-text-field>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" class="white--text" small @click="close">취소</v-btn>
                    <v-btn color="blue darken-1" class="white--text" small @click="save">저장</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col cols="9" >

            <v-row dense>
              <v-col cols="12">
                회원수가 많아짐에 따라 대시보드 형태 및 회원 검색 위주로 변경중입니다.
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="4">
                <v-card class="pa-5 pt-1 pb-1 ma-0" outlined>
                  <v-row dense>
                    <v-col cols="12" class="ma-0 pa-0 subtitle-2">
                      회원수
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="font-weight-bold"  align=center style="font-size: 2em" >
                      {{ member_count }}
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-5 pt-1 pb-1 ma-0" outlined>
                  <v-row dense>
                    <v-col cols="12" class="ma-0 pa-0 subtitle-2">
                      오늘 가입
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="font-weight-bold"  align=center style="font-size: 2em" >
                      {{ today_join }}
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-5 pt-1 pb-1 ma-0" outlined>
                  <v-row dense>
                    <v-col cols="12" class="ma-0 pa-0 subtitle-2">
                      회원수
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="font-weight-bold"  align=center style="font-size: 2em" >
                      {{ member_count }}
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-card outlined class="pa-2">
                  <v-row dense>
                    <v-col cols="12" class="subtitle-2">
                      일정 기간 활동이 있는 회원 조회
                    </v-col>
                    <v-col cols="5" class="subtitle-2 big pa-4">
                      <v-menu
                        v-model="start_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="start_date"
                            prepend-icon="event"
                            label="시작 날짜"
                            readonly
                            v-on="on" >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          header-color="#FBDE44FF"
                          locale="ko-kr"
                          :day-format="getDayFormat"
                          v-model="start_date"
                          @input="start_menu = false"
                          @change="pick_startdate" />
                      </v-menu>
                    </v-col>
                    <v-col cols="5" class="subtitle-2 big pa-4">
                      <v-menu
                        v-model="end_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="end_date"
                            prepend-icon="event"
                            label="끝 날짜"
                            readonly
                            v-on="on" >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          header-color="#FBDE44FF"
                          locale="ko-kr"
                          :day-format="getDayFormat"
                          v-model="end_date"
                          @input="end_menu = false"
                          @change="pick_enddate" />
                      </v-menu>
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" @click="get_active_list">가져오기</v-btn>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      {{ active_list.length }} 명 조회
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="10" class="subtitle-2 big pa-4">
                      <v-text-field
                        v-model="select_count"
                        prepend-icon="event"
                        label="선택 인원수">
                      </v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn color="primary" @click="get_active_select">고르기</v-btn>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" v-for="each in active_select" :key="each._id">
                      {{ each._id }}
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <!--
            <v-data-table
              v-else
              :headers="member_headers"
              :items="member_list"
              :mobile-breakpoint=NaN
              no-data-text="목록이 없습니다."
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  edit
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  delete
                </v-icon>
              </template>

            </v-data-table>
            -->
          </v-col>
        </v-row>
      </v-card>

      <!--




      <v-row dense>
        <v-col md="12" xs="10">
          <v-row class="lighten-5 align-center justify-center">
            <v-card :key="1" elevation=0 flat width="75%">
              <v-toolbar flat dense color="#535a69">
                <span class="font-weight-bold white--text">회원 목록</span>
                <v-spacer />
                <v-toolbar-items>
                  <v-tooltip left nudge-bottom=80 >
                    <template v-slot:activator="{ on }">
                      <v-icon color="white" class="load" v-on="on">mdi-comment-question-outline</v-icon>
                    </template>
                    <span>
                      단백질(배수) 선택 가이드<br/>
                      1.6 : 일반적인 근육증가<br/>
                      2.0 : 저칼로리 식이, 초보자, 타고난사람<br/><br/>
                      - 체지방률 20% 초과인 경우<br/> 20%로 환산하어 계산합니다.
                    </span>
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <v-divider />

            </v-card>
          </v-row>
        </v-col>
      </v-row>
      -->
    </v-container>
  </v-main>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex'


export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      select_count: 0,
      active_list: [],
      active_select: [],
      start_menu: false,
      start_date: "",
      end_menu: false,
      end_date: "",
      loading: true,
      dialog: false,
      today_join: 0,
      member_list: [],
      member_headers: [
        {
          text: '이름',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: '이메일', value: 'email' },
        { text: 'lvl', value: 'lvl' },
        { text: '', value: 'action', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        email: '',
      },
      defaultItem: {
        name: '',
        email: '',
      },
      member_count: 0,

    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      lvl: "lvl",
    })
  },
  created: async function() {
    this.loading = true;
  },
  mounted: async function() {
    await this.reload_init();
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    reload_init: async function() {
      if ( firebase.auth().currentUser ) {
        // 이전 정보가 있는지 확인
        const id_token = await firebase.auth().currentUser.getIdToken();
        const user_count_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/user/count/', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        this.member_count = user_count_.data;

        // const user = await axios.get(
        //   api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/user/list', {
        //   params: { email: this.user.data.email },
        //   headers: {'id_token': id_token},
        // });

        // if (user.data.length>0) {
        //   this.member_list = user.data;
        // }
        const user_join_today = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/statistics/join/', {
          params: {
            email: this.user.data.email,
            date: this.$moment().format("YYYY-MM-DD") },
          headers: {'id_token': id_token},
        });

        this.today_join = user_join_today.data;

        this.start_date = this.$moment().add(-7, 'days').format("YYYY-MM-DD");
        this.end_date = this.$moment().format("YYYY-MM-DD");

        this.loading = false;
      } else {
        setTimeout(() => {
          this.reload_init();
        }, 500);
      }

    },
    close: function () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save: async function () {
      if (this.editedIndex > -1) {
        Object.assign(this.member_list[this.editedIndex], this.editedItem)
      } else {
        this.member_list.push(this.editedItem)
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.put(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/', {
        email: this.user.data.email,
        user: this.editedItem,
      }, {
        headers: {'id_token': id_token},
      });

      this.close()
    },
    editItem: async function (item) {
      this.editedIndex = this.member_list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem: async function (item) {
      const index = this.member_list.indexOf(item)

      if ( this.member_list[index].email == this.user.data.email ) {
        alert("자기 자신을 삭제하시믄 아니되오.");
        return false;
      }

      confirm('삭제하시겠습니까?') && this.member_list.splice(index, 1);
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/', {
          data: {
            email: this.user.data.email,
          },
          headers: {'id_token': id_token},
      });
      const user = firebase.auth().currentUser;
      user.delete().then(function() {
        console.log("bye");
      }).catch(function(error) {
        console.log(error);
      });
    },
    getDayFormat: function(date) {
      return this.$moment(date, "YYYY-MM-DD").format("D");
    },
    pick_startdate(v) {
      // console.log(v);
    },
    pick_enddate(v) {
      // console.log(v);
    },
    get_active_list: async function(e) {

      const day_diff = this.$moment(this.end_date, "YYYY-MM-DD").diff(this.$moment(this.start_date, "YYYY-MM-DD"), 'days') + 1;
      const id_token = await firebase.auth().currentUser.getIdToken();
      const user_active = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/statistics/active/', {
        params: {
          email: this.user.data.email,
          date_from: this.start_date,
          date_to: this.end_date,
          day_diff: day_diff,
        },
        headers: {'id_token': id_token},
      });

      this.active_list = user_active.data;

      console.log(this.active_list);
    },

    get_active_select: async function(e) {

      if ( this.active_list.length < this.select_count ) {
        alert('조회된 인원보다 적은 수를 고르세요.')
        return;
      }

      let tmp_list_ = this.$lodash.cloneDeep(this.active_list);

      this.active_select = [];

      for ( let i = 0 ; i < this.select_count ; i++ ) {

        const chosen_idx = Math.floor(Math.random() * tmp_list_.length);

        const take_1 = tmp_list_[chosen_idx];

        tmp_list_.splice(chosen_idx, 1);

        this.active_select.push(take_1);
      }


    }

  }
}
</script>

<style scoped>

.desc {
  margin-top:10px;
}

.headline {
  font-size: 1.3rem !important;
}

.desc_title {
  font-size:1.1em;
}


</style>
